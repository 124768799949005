import { Star } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useMemo } from 'react';

import { Answer } from '../../../../interfaces/library';

interface Props {
	answer: Answer;
	index: number;
	handleSelectAnswer: (answer: Answer, index: number) => void;
	activeAnswer: number;
	activeQuestion: number;
	showCorrect: boolean;
	correctAnswer: number;
}

const indexColor = {
	0: '#c472ac',
	1: '#4f8ecb',
	2: '#6ea9a0',
} as any;
const AnswerBox = ({
	answer,
	index,
	handleSelectAnswer,
	activeAnswer,
	activeQuestion,
	showCorrect,
	correctAnswer,
}: Props) => {
	const classBox = useMemo(() => {
		let classes =
			'cursor-pointer bg-white overflow-hidden relative flex flex-row shadow-lg justify-center items-center w-full mx-auto mt-5 p-5 rounded-md !text-black/60 ';

		if (showCorrect) {
			classes += '!cursor-not-allowed	 ';
		}

		if (index === activeAnswer) {
			classes += '!bg-[#9ae1e4] border-[2px] border-[#2a9499] ';
		} else {
			classes += 'border-[2px] border-gray-400 ';
		}

		if (showCorrect && index === correctAnswer) {
			classes += '!bg-green-400 border-[2px] border-green-400 ';
		} else if (showCorrect && index !== correctAnswer && index === activeAnswer) {
			classes += '!bg-red-400 border-[2px] border-red-400';
		}
		return classes;
	}, [index, activeAnswer, correctAnswer, showCorrect]);

	if (answer.text) {
		return (
			<Box
				key={index}
				className={classBox}
				onClick={() => {
					handleSelectAnswer(answer, index);
				}}
			>
				<Star
					className={`w-24 h-32 absolute left-0 p-5 bg-grey-200 ${
						index === activeAnswer ? 'text-black/60 bg-[#81bdc1]' : 'text-transparent'
					}`}
				/>
				<Typography
					variant="body1"
					className={`text-center ${index === activeAnswer ? 'text-black' : 'text-gray-500'}`}
				>
					<audio id={`audio-${activeQuestion}-${index}`} hidden>
						<source src={answer?.audio?.url} type="audio/mpeg" />
						Your browser does not support the audio element.
					</audio>
					{answer.text ? answer.text : String.fromCharCode(65 + index)}
				</Typography>
			</Box>
		);
	}
	if (answer.audio) {
		return (
			<Box
				key={index}
				className={classBox + 'h-96'}
				onClick={() => {
					if (!showCorrect) {
						handleSelectAnswer(answer, index);
					}
				}}
			>
				<Star
					className={`w-24 h-full absolute left-0 p-5 ${
						index === activeAnswer ? 'text-black/60 bg-[#81bdc1]' : 'bg-grey-200 text-transparent'
					}`}
					style={{
						background: index === activeAnswer ? 'rgba(0,0,0,.1)' : 'rgba(0,0,0,.05)',
					}}
				/>
				<Typography
					variant="body1"
					className={`text-center ${index === activeAnswer ? 'text-black' : 'text-gray-500'}`}
				>
					<audio id={`audio-${activeQuestion}-${index}`} hidden>
						<source src={answer?.audio?.url} type="audio/mpeg" />
						Your browser does not support the audio element.
					</audio>
					<img
						className={`w-72 h-72 ${index === activeAnswer ? 'text-black/60' : 'text-transparent'}`}
						style={{
							color: showCorrect ? 'rgba(0,0,0,.5)' : indexColor[index],
						}}
						src={answer?.image?.url}
					/>
				</Typography>
			</Box>
		);
	}
	if (answer.image) {
		return (
			<Box
				key={index}
				className={classBox + 'h-96'}
				onClick={() => {
					if (!showCorrect) {
						handleSelectAnswer(answer, index);
					}
				}}
			>
				<Star
					className={`w-24 h-full absolute left-0 p-5 ${
						index === activeAnswer ? 'text-black/60 bg-[#81bdc1]' : 'bg-grey-200 text-transparent'
					}`}
					style={{
						background: index === activeAnswer ? 'rgba(0,0,0,.1)' : 'rgba(0,0,0,.05)',
					}}
				/>
				<Typography
					variant="body1"
					className={`text-center ${index === activeAnswer ? 'text-black' : 'text-gray-500'}`}
				>
					<img
						className={`w-72 h-72 ${index === activeAnswer ? 'text-black/60' : 'text-transparent'}`}
						style={{
							color: showCorrect ? 'rgba(0,0,0,.5)' : indexColor[index],
						}}
						src={answer?.image?.url}
					/>
				</Typography>
			</Box>
		);
	}
};

export default AnswerBox;
