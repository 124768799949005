import { ChildCare, House, LibraryBooks } from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import { Avatar, Button, Divider, ListItemIcon, ListItemText, MenuItem, Popover } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { Link } from 'react-router-dom';

import { useAuth } from '../../../../hooks/useAuth';
import { useUser } from '../../../../hooks/useUser';

const UserMenu = () => {
	const { userInfo, studentSelected, courseSelected, clearCourseSelected, clearStudentSelected } = useUser();

	const handleSelectProfile = () => {
		clearStudentSelected();
		clearCourseSelected();
	};

	const { handleLogout } = useAuth();
	const [userMenu, setUserMenu] = useState<HTMLElement | null>(null);

	const userMenuClick = (event: MouseEvent<HTMLElement>) => {
		setUserMenu(event.currentTarget);
	};

	const userMenuClose = () => {
		setUserMenu(null);
	};

	return (
		<>
			<Button id="basic-button" className={`flex flex-row gap-2 text-gray-800 hover:text-[#e94f35]`}>
				<Link to="">
					<House /> Estadisticas
				</Link>
			</Button>

			{userInfo?.role !== 'school-manager' && (
				<>
					<Link
						to="/library"
						className="hover:text-[#c472ac] flex flex-row gap-2 transition-colors duration-200 text-gray-800"
					>
						<LibraryBooks className="mr-3" />
						Librería
					</Link>
					<Button
						variant="contained"
						onClick={handleSelectProfile}
						className="ml-10 w-full max-w-[15em] bg-[#c472ac] text-white font-medium h-32 shadow-0 hover:bg-[#b15094]"
					>
						<ChildCare className="w-20 h-20 mr-3" />
						{studentSelected?.name} {courseSelected?.name} - {userInfo?.firstSurname}
					</Button>
				</>
			)}
			<Button className="min-h-40 min-w-40 p-0 mr-11 box-border" onClick={userMenuClick} color="inherit">
				<Avatar>{userInfo?.name[0]}</Avatar>
			</Button>

			<Popover
				open={!!userMenu}
				anchorEl={userMenu}
				onClose={userMenuClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				classes={{
					paper: 'py-8',
				}}
			>
				<MenuItem component={Link} to="/profile" onClick={userMenuClose} role="button">
					<ListItemIcon>
						<PersonIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText primary="Mi perfil" />
				</MenuItem>
				<Divider />
				<MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
			</Popover>
		</>
	);
};

export { UserMenu };
