import { Alert } from '@mui/material';
import { Box } from '@mui/system';

import { useAlert } from '../../hooks/useAlert';

const CustomAlert = () => {
	const { show, type, message, handleCloseAlert } = useAlert();

	return (
		show && (
			<Box className="absolute top-0 right-0 left-0 mx-auto mt-20 z-9999 w-fit min-w-xs">
				<Alert onClose={handleCloseAlert} severity={type}>
					{message}
				</Alert>
			</Box>
		)
	);
};

export { CustomAlert };
