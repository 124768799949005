import { PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';

interface WrapperPageProps {
	title: string;
}

const WrapperPage = ({ children, title }: PropsWithChildren<WrapperPageProps>) => {
	return (
		<>
			<Helmet>
				<title>{title} | Kidint - Aplicación de lectura infantil</title>
			</Helmet>
			{children}
		</>
	);
};

export { WrapperPage };
