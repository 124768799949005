import { lazy } from 'react';

import { DashboardLayout } from '../layouts/dashboard';
import BookView from '../views/book';

const SignInView = lazy(() => import('../views/auth/sign-in'));
const RecoveryPasswordView = lazy(() => import('../views/auth/recovery-password'));
const HomeView = lazy(() => import('../views/home'));
const CategoryView = lazy(() => import('../views/category'));
const LibraryView = lazy(() => import('../views/library'));
const ProfileView = lazy(() => import('../views/profile'));

export const routesConfig = [
	{
		path: '/auth/sign-in',
		element: <SignInView />,
	},
	{
		path: '/auth/recovery-password',
		element: <RecoveryPasswordView />,
	},
	{
		element: <DashboardLayout />,
		path: '/',
		children: [
			{
				index: true,
				element: <HomeView />,
			},
			{
				path: 'library',
				element: <LibraryView />,
			},
			{
				path: 'categories/:category',
				element: <CategoryView />,
			},
			{
				path: 'books/:id',
				element: <BookView />,
			},
			{
				path: 'profile',
				element: <ProfileView />,
			},
		],
	},
	{
		path: '*',
		element: <DashboardLayout />,
		children: [
			{
				index: true,
				element: <HomeView />,
			},
		],
	},
];
