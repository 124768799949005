import { Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router';

import { useUser } from '../../hooks/useUser';

const ProfileSelectorPage = () => {
	const { selectStudent, selectCourse, userInfo } = useUser();
	const navigate = useNavigate();

	const handleSelectStudent = (studentUID: string) => {
		selectStudent(studentUID);
		navigate('/library');
	};

	const handleSelectCourse = (courseUID: string) => {
		selectCourse(courseUID);
		navigate('/library');
	};

	return (
		<Box className="absolute w-screen h-screen bg-black z-9999">
			<Box className="absolute w-full h-full flex flex-col gap-20 items-center justify-center">
				<Typography className="text-white text-2xl font-medium" variant="h1">
					Selecciona un {userInfo?.students ? 'estudiante' : 'curso'}
				</Typography>
				<Box
					className={`mt-20 overflow-auto max-h-[65vh] py-20 gap-20 w-10/12 mx-auto ${
						(userInfo?.role === 'parent' && userInfo?.students.length > 3) ||
						((userInfo?.role === 'teacher' || userInfo?.role === 'school-manager') && userInfo?.courses.length > 3)
							? 'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4'
							: 'flex flex-wrap flex-row justify-center items-center'
					}`}
				>
					{userInfo?.students &&
						userInfo?.students.map(
							(student) =>
								student.isActive && (
									<motion.div
										key={student._uid}
										className="flex flex-col items-center gap-10 col-span-1"
										whileHover={{ scale: 1.1 }}
									>
										<Box
											className="bg-grey-900 rounded-full text-white text-3xl font-bold w-72 h-72 flex items-center justify-center cursor-pointer"
											onClick={() => {
												handleSelectStudent(student._uid);
											}}
										>
											{student.name.charAt(0).toUpperCase() + student.name.charAt(1).toUpperCase()}
										</Box>

										<Typography className="text-white" variant="body2">
											{student.name} {student.firstSurname}
										</Typography>
									</motion.div>
								),
						)}
					{userInfo?.courses?.map(
						(course) =>
							course.isActive &&
							course.subjects.map(
								(subject) =>
									subject.isActive && (
										<motion.div
											key={course._uid}
											className="flex flex-col items-center gap-10 col-span-1"
											whileHover={{ scale: 1.1 }}
										>
											<Box
												className="bg-grey-900 rounded-full text-white text-3xl font-bold w-72 h-72 flex items-center justify-center cursor-pointer"
												onClick={() => {
													handleSelectCourse(course._uid);
												}}
											>
												{course.section}
											</Box>

											<Typography className="text-white w-10/12 mx-auto text-center" variant="body2">
												{course.name}
												<br />
												{subject.name}
											</Typography>
										</motion.div>
									),
							),
					)}
				</Box>
			</Box>
		</Box>
	);
};

export { ProfileSelectorPage };
