import { StyledEngineProvider } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import es from 'date-fns/esm/locale/es';
import setDefaultOptions from 'date-fns/setDefaultOptions';
import { Suspense } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { LoadingPage } from './components/loading-page';
import { routesConfig } from './routes/routes';

const router = createBrowserRouter(routesConfig);
setDefaultOptions({ locale: es });

const App = () => {
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<StyledEngineProvider injectFirst>
				<Suspense fallback={<LoadingPage />}>
					<RouterProvider router={router} />
				</Suspense>
			</StyledEngineProvider>
		</LocalizationProvider>
	);
};

export { App };
