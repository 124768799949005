import { useEffect, useRef, useState } from 'react';

const useDetectZoom = () => {
	const [zoomLevel, setZoomLevel] = useState(1);
	const prevZoomLevelRef = useRef(1);

	useEffect(() => {
		const detectZoomLevel = () => {
			const ratio = window.outerWidth / window.innerWidth;
			prevZoomLevelRef.current = zoomLevel;
			setZoomLevel(ratio);
		};

		window.addEventListener('resize', detectZoomLevel);

		detectZoomLevel();

		return () => window.removeEventListener('resize', detectZoomLevel);
	}, [zoomLevel]);

	return { currentZoom: zoomLevel, previousZoom: prevZoomLevelRef.current };
};

export { useDetectZoom };
