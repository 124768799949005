import { CheckCircleOutline, LibraryBooks } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { useUser } from '../../../hooks/useUser';
import { Question } from '../../../interfaces/library';
import { BooksServices } from '../../../services/books';
import { shuffleQuestions } from '../../../utils/helper';
import AnswerBox from './answerBox';

interface FinalQuestionsProps {
	setLoadingQuestions: React.Dispatch<React.SetStateAction<boolean>>;
}

const FinalQuestions = ({ setLoadingQuestions }: FinalQuestionsProps) => {
	const { studentSelected, courseSelected, userInfo } = useUser();
	const id = useParams<{ id: string }>().id;
	const [questions, setQuestions] = useState<Question[] | null>(null);
	const [answers, setAnswers] = useState<
		{
			question: string;
			answer: string;
		}[]
	>([]);
	const [activeQuestion, setActiveQuestion] = useState<number>(0);
	const [activeAnswer, setActiveAnswer] = useState<number>(-1);
	const [showingCorrect, setShowingCorrect] = useState<boolean>(false);
	const navigate = useNavigate();

	useEffect(() => {
		const getQuestions = async () => {
			try {
				const ifIsStudent = {
					schoolId: studentSelected?.schoolId,
					courseId: studentSelected?.courseId,
				};
				const ifIsTeacher = {
					schoolId: userInfo?.schoolId,
					courseId: courseSelected?._uid,
				};
				const dataHeaders = userInfo?.role === 'parent' ? ifIsStudent : ifIsTeacher;
				const { questions } = await BooksServices.getQuestions(
					id as string,
					dataHeaders.schoolId as string,
					dataHeaders.courseId as string,
				);
				const shuffledQuestions = await shuffleQuestions(questions);
				setQuestions(shuffledQuestions);
				setLoadingQuestions(false);
				if (questions.length === 0) {
					setTimeout(() => {
						navigate(`/library`);
					}, 1000);
				}
			} catch (error) {
				setTimeout(() => {
					navigate(`/library`);
				}, 1000);
				console.log(error);
			}
		};

		getQuestions();
	}, []);

	const playAudio = (audioUrl: string, questionIndex: number, answerIndex: number) => {
		const audioId = `audio-${questionIndex}-${answerIndex}`;
		const audios = document.getElementsByTagName('audio');
		for (let i = 0; i < audios.length; i++) {
			audios[i].pause();
			audios[i].currentTime = 0;
		}
		const audioPlayer = document.getElementById(audioId) as HTMLAudioElement;
		if (audioPlayer) {
			audioPlayer.src = audioUrl;
			audioPlayer.pause();
			audioPlayer.currentTime = 0;
			audioPlayer.play();
		}
	};

	const playQuestionAudio = (audioUrl: string) => {
		const audioId = `audio_question`;
		const audioPlayer = document.getElementById(audioId) as HTMLAudioElement;
		if (audioPlayer) {
			audioPlayer.src = audioUrl;
			audioPlayer.pause();
			audioPlayer.currentTime = 0;
			audioPlayer.play();
		}
	};

	const handleSelectAnswer = (answer: any, index: number) => {
		playAudio(answer?.audio?.url, activeQuestion, index);
		if (questions == null) return;
		const questionIndex = answers.findIndex((item) => item.question === questions[activeQuestion]?.uid);
		if (questionIndex !== -1) {
			const newAnswers = [...answers];
			newAnswers[questionIndex].answer = answer.uid;
			setAnswers(newAnswers);
		} else {
			setAnswers((prev) => [...prev, { question: questions[activeQuestion]?.uid, answer: answer.uid }]);
		}
		setActiveAnswer(index);
	};

	const handleNextQuestion = () => {
		if (questions == null) return;
		setShowingCorrect(true);
		const audios = document.getElementsByTagName('audio');
		for (let i = 0; i < audios.length; i++) {
			audios[i].pause();
			audios[i].currentTime = 0;
		}
		handleResponseAnswer(answers.length - 1);
		const audioCongrats = document.getElementById('audio_question_lottie') as HTMLAudioElement;
		if (showingCorrect && activeAnswer === questions[activeQuestion]?.answers.findIndex((item) => item.isCorrect)) {
			audioCongrats.play();
		}
		setTimeout(() => {
			setActiveQuestion((prev) => prev + 1);
			setActiveAnswer(-1);
			playQuestionAudio(questions[activeQuestion + 1]?.audio?.url);
			setShowingCorrect(false);
		}, 1500);
	};

	const handleResponseAnswer = async (index: number) => {
		try {
			if (questions == null) return;
			const event = {
				type: 'question_answered' as const,
				questionID: questions[index].uid,
				answerID: answers[index].answer,
			};
			const idToSend = studentSelected ? studentSelected._uid : courseSelected?._uid;
			await BooksServices.sendEvents(idToSend as string, id as string, [event]);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		questions !== null &&
		questions.length >= 1 && (
			<Box className="w-full h-full">
				{showingCorrect && activeAnswer === questions[activeQuestion]?.answers.findIndex((item) => item.isCorrect) && (
					<>
						<lottie-player
							src="https://lottie.host/b1d8b123-1b42-421f-ba5d-78bec6fb49c4/rpo1rfsRne.json"
							background="transparent"
							speed="1"
							style={{ width: '100%', height: '100%', position: 'absolute', left: 0, right: 0, zIndex: 9999999999 }}
							autoplay
							direction="1"
							mode="normal"
						></lottie-player>
						<audio controls autoPlay hidden id={`audio_question_lottie`}>
							<source src="/audio/correct_answer_song.mp3" type="audio/mpeg" />
							Your browser does not support the audio element.
						</audio>
					</>
				)}

				{questions[activeQuestion] && (
					<Box
						className={`relative flex flex-col h-full w-full z-10 p-20 ${
							questions[activeQuestion]?.image?.url ? '' : 'mt-20'
						}`}
					>
						<Typography
							variant="h6"
							className="text-white text-center mt-5"
							style={{
								textShadow: '0px 0px 10px rgba(0, 0, 0, 0.9)',
							}}
						>
							{questions[activeQuestion]?.title}
						</Typography>
						{questions[activeQuestion]?.image?.url && (
							<img
								src={questions[activeQuestion]?.image?.url}
								alt={'Portada'}
								className="w-[30%] mt-5 mx-auto border-[10px] rounded-md border-white object-cover"
							/>
						)}
						{questions[activeQuestion]?.audio?.url && (
							<audio controls autoPlay hidden id={`audio_question`}>
								<source src={questions[activeQuestion]?.audio?.url} type="audio/mpeg" />
								Your browser does not support the audio element.
							</audio>
						)}
						<Box
							className={`mt-10 ${
								!questions[activeQuestion]?.answers[0].text ? 'grid grid-cols-3 gap-10' : 'grid grid-cols-1 gap-5'
							}`}
						>
							{questions[activeQuestion]?.answers.map((answer, index) => (
								<AnswerBox
									key={index}
									answer={answer}
									index={index}
									handleSelectAnswer={handleSelectAnswer}
									activeAnswer={activeAnswer}
									activeQuestion={activeQuestion}
									showCorrect={showingCorrect}
									correctAnswer={questions[activeQuestion]?.answers.findIndex((item) => item.isCorrect)}
								/>
							))}
						</Box>
						<Button
							sx={{
								width: 200,
								margin: 'auto',
								marginTop: 2,
								backgroundColor: 'green',
								'&:hover': {
									backgroundColor: 'green',
									boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
								},
								border: 4,
								borderColor: 'white',
								borderRadius: '10px',
								'&:disabled': {
									backgroundColor: '#9ae1e4',
									opacity: 0.5,
								},
							}}
							className={`bg-green-400 ${
								questions[activeQuestion]?.image?.url ? 'bottom-[4%] py-3' : 'mt-20  bottom-[15%] py-6'
							}
							 absolute left-0 right-0 mx-auto`}
							variant="contained"
							disabled={activeAnswer === -1}
							onClick={() => {
								handleNextQuestion();
							}}
						>
							<CheckCircleOutline className="mr-4 w-14" />
							Siguiente
						</Button>
					</Box>
				)}
				{!questions[activeQuestion] && questions.length >= 1 && (
					<Box className="flex overflow-hidden w-full h-full relative">
						<Link to={`/library`}>
							<Button
								sx={{
									width: 150,
									margin: 'auto',
									marginTop: 2,
									backgroundColor: '#4dbcdc',
									'&:hover': {
										backgroundColor: '#4dbcdc',
										boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
									},
									border: 4,
									borderColor: 'white',
									borderRadius: '10px',
								}}
								className={`bg-blue-400 ${
									(questions[activeQuestion] as any)?.image?.url ? 'py-2' : 'py-6'
								} absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2`}
								variant="contained"
							>
								<LibraryBooks className="mr-4 w-14" />
								Libreria
							</Button>
						</Link>
						<motion.img
							src="/images/ballons1.png"
							alt="balls"
							className="absolute top-12 right-12 z-0 object-contain w-144"
							initial={{ opacity: 0, scale: 0.9, translateY: 100 }}
							animate={{ opacity: 1, scale: 1, translateY: 0 }}
							transition={{ duration: 1 }}
						/>
						<motion.img
							src="/images/ballons2.png"
							alt="balls"
							className="absolute top-12 left-12 z-0 object-contain w-144"
							initial={{ opacity: 0, scale: 0.9, translateY: 100 }}
							animate={{ opacity: 1, scale: 1, translateY: 0 }}
							transition={{ duration: 1 }}
						/>
						<motion.img
							src="/images/congratulations.png"
							alt="balls"
							className="absolute top-0 left-0 right-0 mx-auto z-0 object-contain w-9/12 translate-y-112"
							initial={{ opacity: 0, scale: 0.9, translateY: 300 }}
							animate={{ opacity: 1, scale: 1, translateY: 180 }}
							transition={{ duration: 1 }}
						/>{' '}
						<div className="play-animation-pig absolute -bottom-40 left-32 z-0 object-contain overflow-hidden" />
						<div className="play-animation-monkey absolute -bottom-40 right-32 z-0 object-contain overflow-hidden" />
						<audio controls autoPlay id="audio_question" hidden>
							<source src="/audio/congratulations.mp3" type="audio/mpeg" />
							Your browser does not support the audio element.
						</audio>
					</Box>
				)}
			</Box>
		)
	);
};

export default FinalQuestions;
