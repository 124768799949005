import $ from 'jquery';
import { useEffect, useRef } from 'react';

const Turn = (props: {
	className: string;
	style?: any;
	options: {
		width: number;
		height: number;
		autoCenter: boolean;
		display: string;
		acceleration: boolean;
		gradients: boolean;
		elevation: number;
		when: any;
	};
	children: any;
}) => {
	let fadeClass = useRef('');

	useEffect(() => {
		if (fadeClass) {
			$(fadeClass).turn({
				width: props.options.width,
				height: props.options.height,
				autoCenter: props.options.autoCenter,
				display: props.options.display,
				acceleration: props.options.acceleration,
				gradients: props.options.gradients,
				elevation: props.options.elevation,
				when: props.options.when,
				pages: props.children.length,
			});
		}
	}, []);

	return (
		<div
			className={props.className}
			style={Object.assign({}, props.style)}
			ref={(el) => {
				fadeClass = el as any;
			}}
		>
			{props.children}
		</div>
	);
};

export default Turn;
