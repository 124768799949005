import _ from 'lodash';
import { useNavigate } from 'react-router';

import { UserServices } from '../services/user';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
	clearSelectedCourse,
	clearSelectedStudent,
	setSelectedCourse,
	setSelectedStudent as setSelectedStudentSlice,
	setUserInfo as setUserInfoSlice,
} from '../store/user';

const useUser = () => {
	const dispatch = useAppDispatch();
	const userInfo = useAppSelector((state) => state.user);
	const navigate = useNavigate();

	const setUserInfo = async () => {
		try {
			const response = await UserServices.me();
			dispatch(setUserInfoSlice({ info: response?.data.user }));
			if (response?.data.user.role === 'school-manager') {
				navigate('/');
			} else {
				navigate('/library');
			}
		} catch (error) {
			return error;
		}
	};

	const selectStudent = (profileId: string) => {
		const profileFound = userInfo?.info?.students.find((student) => student._uid === profileId);
		if (!_.isEmpty(profileFound)) dispatch(setSelectedStudentSlice(profileFound));
	};

	const selectCourse = (courseId: string) => {
		const courseFound = userInfo?.info?.courses.find((course) => course._uid === courseId);
		if (!_.isEmpty(courseFound)) dispatch(setSelectedCourse(courseFound));
	};

	const clearStudentSelected = () => {
		dispatch(clearSelectedStudent());
	};

	const clearCourseSelected = () => {
		dispatch(clearSelectedCourse());
	};

	return {
		userInfo: userInfo.info,
		studentSelected: userInfo.studentSelected,
		courseSelected: userInfo.courseSelected,
		setUserInfo,
		selectCourse,
		selectStudent,
		clearStudentSelected,
		clearCourseSelected,
	};
};

export { useUser };
