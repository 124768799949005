import { ClientResponse } from '../interfaces/common';
import { MeResponse, UserInfo } from '../interfaces/user';
import { get, put } from '../utils/api';

const me = async () => {
	const { data } = await get<ClientResponse<MeResponse>>('/user/me');
	return data;
};

const updateUser = async (uid: string, data: Omit<UserInfo, 'isActive'>) => {
	const { data: res } = await put<ClientResponse<UserInfo>>(`/user/update/${uid}`, data);
	return res;
};

export const UserServices = {
	me,
	updateUser,
};
