import { Box } from '@mui/material';
import { motion } from 'framer-motion';
import { PropsWithChildren } from 'react';

const Background = ({
	children,
	showBackground = true,
}: PropsWithChildren<{
	showBackground?: boolean;
}>) => {
	return (
		<Box className={`absolute overflow-hidden top-0 left-0 min-h-screen`}>
			{showBackground && (
				<>
					<Box className="fixed top-0 left-0 w-full h-full bg-[#4f8ecb] opacity-50" />

					<motion.img
						src="/images/monkey-flying.png"
						alt="animal2"
						className="z-10 fixed max-w-[18em] object-cover left-[2%] hidden lg:block top-[45%]"
						animate={{ y: ['-10%', '10%', '-10%'] }}
						transition={{ y: { repeat: Infinity, duration: 25, ease: 'easeInOut' } }}
						variants={{ tap: { scale: 1.1 } }}
					/>

					<motion.img
						src="/images/lion-flying.png"
						alt="animal2"
						className="z-10 fixed max-w-[18em] object-cover right-[2%] hidden lg:block top-[20%]"
						animate={{ y: ['-10%', '10%', '-10%'] }}
						transition={{ y: { repeat: Infinity, duration: 15, ease: 'easeInOut' } }}
						variants={{ tap: { scale: 1.1 } }}
					/>

					<Box
						style={{
							backgroundImage: 'url(/images/clouds.png)',
							backgroundRepeat: 'repeat-x',
							position: 'fixed',
							width: '100%',
							minHeight: '100vh',
							left: '0',
							top: '0',
							overflow: 'hidden',
							backgroundPosition: 'center',
						}}
					/>

					<Box
						style={{
							backgroundImage: 'url(/images/mountains.png)',
							backgroundRepeat: 'repeat-x',
							position: 'fixed',
							width: '100%',
							minHeight: '100vh',
							left: '0',
							overflow: 'hidden',
							top: '0',
							backgroundPosition: 'bottom',
						}}
					/>
				</>
			)}
			{children}
		</Box>
	);
};

export { Background };
