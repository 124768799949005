import { createContext, PropsWithChildren, useEffect, useState } from 'react';

export type AlertTypesAllowed = 'success' | 'error' | 'warning' | undefined;

export interface AlertProviderProps {
	show: boolean;
	type: AlertTypesAllowed;
	message: string;
}

export interface AlertProviderBase extends AlertProviderProps {
	handleCloseAlert: () => void;
	handleShowAlert: ({ type, message }: Omit<AlertProviderProps, 'show'>) => void;
}

export const defaultAlertValues: AlertProviderBase = {
	type: undefined,
	message: '',
	show: false,
	handleCloseAlert: () => ({}),
	handleShowAlert: () => ({}),
};

export const AlertContext = createContext<AlertProviderBase>(defaultAlertValues);

export const AlertProvider = ({ children }: PropsWithChildren<AlertProviderBase>) => {
	const [state, setState] = useState(defaultAlertValues);

	useEffect(() => {
		if (state.show) {
			setTimeout(() => {
				setState(defaultAlertValues);
			}, 5000);
		}
	}, [state.show]);

	const handleCloseAlert = () => {
		setState({ ...state, show: false });
	};

	const handleShowAlert = ({ type, message }: Omit<AlertProviderProps, 'show'>) => {
		setState({ ...state, show: true, type, message });
	};

	return (
		<AlertContext.Provider value={{ ...state, handleCloseAlert, handleShowAlert }}>{children}</AlertContext.Provider>
	);
};
