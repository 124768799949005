import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Course, Student, UserState } from '../../interfaces/user';

const initialState: UserState = {
	info: undefined,
	studentSelected: undefined,
	courseSelected: undefined,
};

export const userSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setUserInfo: (state, action: PayloadAction<UserState>) => {
			state.info = action.payload.info;
		},
		clearUserInfo: (state) => {
			state.info = undefined;
			state.studentSelected = undefined;
			state.courseSelected = undefined;
		},
		setSelectedStudent: (state, action: PayloadAction<Student>) => {
			state.studentSelected = action.payload;
		},
		clearSelectedStudent: (state) => {
			state.studentSelected = undefined;
		},
		setSelectedCourse: (state, action: PayloadAction<Course>) => {
			state.courseSelected = action.payload;
		},
		clearSelectedCourse: (state) => {
			state.courseSelected = undefined;
		},
	},
});

export const {
	setUserInfo,
	clearUserInfo,
	setSelectedStudent,
	clearSelectedStudent,
	setSelectedCourse,
	clearSelectedCourse,
} = userSlice.actions;
export default userSlice.reducer;
