import { Box, Typography } from '@mui/material';
import { motion } from 'framer-motion';

const LoadingPage = () => {
	const variants = {
		animate: (delay: number) => ({
			y: [-20, 20, -20],
			transition: {
				repeat: Infinity,
				repeatDelay: 0.5,
				ease: 'easeInOut',
				duration: 1,
				delay: delay,
			},
		}),
	};

	return (
		<Box className="absolute w-full h-full z-9999 overflow-hidden">
			<Box className="absolute w-full h-full bg-black/80 flex flex-col items-center justify-center">
				<Box className="flex flex-row gap-[4em] justify-center items-center">
					<motion.img
						src="/images/animal1.png"
						custom={0.2}
						variants={variants}
						initial={{ y: 0 }}
						animate="animate"
						className="w-full max-w-[25vw]"
					/>
					<motion.img
						src="/images/animal2.png"
						custom={0.4}
						variants={variants}
						initial={{ y: 0 }}
						animate="animate"
						className="w-full max-w-[25vw]"
					/>
					<motion.img
						src="/images/animal3.png"
						custom={0.6}
						variants={variants}
						initial={{ y: 0 }}
						animate="animate"
						className="w-full max-w-[25vw]"
					/>
				</Box>

				<Typography className="mt-20 text-4xl text-white font-medium">Por favor, espere...</Typography>
			</Box>
		</Box>
	);
};

export { LoadingPage };
