import { Lock } from '@mui/icons-material';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router';

import { Page } from '../../../interfaces/library';

interface FinalQuestionsProps {
	setPageActive: React.Dispatch<React.SetStateAction<number>>;
	pagesData: Page[];
	visitedPages: number[];
	setViewQuestions: React.Dispatch<React.SetStateAction<boolean>>;
	turn: (_: any, page: number) => void;
}
const FinalQuestions = (props: FinalQuestionsProps) => {
	const { visitedPages, pagesData, setPageActive } = props;
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);

	const handleClickOpenDialog = () => {
		setOpen(true);
	};

	const handleCloseDialog = () => {
		setOpen(false);
	};

	return (
		<Box className="relative h-full w-full">
			<Box className="relative flex flex-col h-full w-full z-10">
				<img
					src={pagesData[0]?.image?.url || '/images/cover.png'}
					alt={'Portada'}
					className="w-[40%] mt-10 mx-auto border-[10px] rounded-md border-white object-cover"
				/>
			</Box>
			<Box className="absolute bottom-0 left-0 w-full h-[45%] bg-[#37c5cb] z-10 flex flex-col justify-center items-center gap-5">
				<Typography variant="h4" className="text-[#393939] font-beba uppercase w-5/6 text-center mt-10">
					Acabas de terminar de leer <span className="text-white">{pagesData[0].subtitle}</span>
				</Typography>
				<Box className="flex flex-row gap-20 -mt-10 items-center justify-center">
					<Box className="flex flex-col items-center justify-center">
						<IconButton
							onClick={(e) => {
								setPageActive(0);
								props.turn(e, 1);
							}}
						>
							<img src="/images/btnPlayAgain.png" alt="autoPlayIcon" className="w-48 h-48 object-contain" />
						</IconButton>
						<Typography variant="body2" className="text-[#393939] font-bold text-center">
							Volver a leer
						</Typography>
					</Box>
					<Box className="flex flex-col items-center justify-center">
						<IconButton onClick={() => navigate('/library')}>
							<img src="/images/libraryIcon.png" alt="autoPlayIcon" className="w-48 h-48 object-contain" />
						</IconButton>
						<Typography variant="body2" className="text-[#393939] font-bold text-center">
							Volver al catalogo
						</Typography>
					</Box>
					<Box className="mb-12 flex flex-col items-center justify-center">
						<IconButton
							onClick={() => {
								const audioBG = document.getElementById('audio_background') as HTMLAudioElement;
								audioBG.pause();
								audioBG.currentTime = 0;
								if (new Set(visitedPages).size - 1 !== (pagesData?.length as number)) {
									handleClickOpenDialog();
									return;
								}
								props.setViewQuestions(true);
							}}
							className="relative"
						>
							<img src="/images/btnPlayRoom.png" alt="autoPlayIcon" className="w-48 h-60 object-contain" />
							<Box
								className={`absolute top-24 left-16 w-full h-full flex flex-col items-center justify-center gap-5 ${
									new Set(visitedPages).size - 1 === pagesData?.length ? 'hidden' : 'opacity-100'
								}`}
							>
								<Lock className="w-20 h-20 text-white bg-red-400 rounded-full p-5 shadow-2xl" />
							</Box>
						</IconButton>
						<Typography variant="body2" className="text-[#393939] font-bold text-center">
							Playroom
						</Typography>
					</Box>
				</Box>
			</Box>
			<Dialog
				open={open}
				onClose={handleCloseDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				PaperProps={{
					className: 'rounded-md bg-[#c472ac] text-white border-[1px] border-white',
				}}
			>
				<DialogTitle className="font-bold" id="alert-dialog-title">
					{'Aun no puedes contestar las preguntas'}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description" className="text-white/90">
						Para poder contestar las preguntas, debes leer todas las páginas del libro.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseDialog} autoFocus className="text-white font-medium font-sans">
						Cerrar
					</Button>
				</DialogActions>
			</Dialog>
			<img
				src="/images/bgQuestionsPage.png"
				alt="bgQuestions"
				className="absolute top-0 left-0 w-full h-full object-cover"
			/>
		</Box>
	);
};

export default FinalQuestions;
