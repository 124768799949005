import { IconButton, Tooltip, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router';

const slotProps = {
	popper: {
		modifiers: [
			{
				name: 'offset',
				options: {
					offset: [0, -14],
				},
			},
		],
	},
	tooltip: {
		sx: {
			backgroundColor: '#1C1C1C',
			color: '#fff',
			boxShadow: '0 0 5px #000',
			fontSize: '14px',
			borderRadius: '5px',
			padding: '5px 10px',
		},
	},
	arrow: {
		sx: {
			color: '#1C1C1C',
			'&::before': {
				boxShadow: '0 0 5px #000',
			},
		},
	},
};

interface PlayOptionsProps {
	playOptions: {
		autoPlay: boolean;
		audioVoice: boolean;
		backgroundMusic: boolean;
		fullScreen: boolean;
		subtitles: boolean;
	};
	setPlayOptions: React.Dispatch<
		React.SetStateAction<{
			autoPlay: boolean;
			audioVoice: boolean;
			backgroundMusic: boolean;
			fullScreen: boolean;
			subtitles: boolean;
		}>
	>;
}
const PlayOptions = (props: PlayOptionsProps) => {
	const { playOptions, setPlayOptions } = props;
	const navigate = useNavigate();

	return (
		<Box className="flex flex-col justify-start items- py-10 w-64 h-[70vh] my-auto absolute rounded-r-lg -right-64 bg-[#335d7e] z-9999 top-0 bottom-0">
			<Tooltip
				title={<Typography fontSize={14}>Auto avance</Typography>}
				placement="top"
				arrow
				slotProps={slotProps}
				className="py-2"
			>
				<IconButton onClick={() => setPlayOptions((prev) => ({ ...prev, autoPlay: !prev.autoPlay }))}>
					<img
						src="/images/autoPlayIcon.png"
						alt="autoPlayIcon"
						className={`w-48 h-48 object-contain ${!playOptions.autoPlay ? 'opacity-50' : ''}`}
					/>
				</IconButton>
			</Tooltip>

			<Tooltip
				title={<Typography fontSize={14}>Música de fondo</Typography>}
				placement="top"
				arrow
				slotProps={slotProps}
				className="py-2"
			>
				<IconButton onClick={() => setPlayOptions((prev) => ({ ...prev, backgroundMusic: !prev.backgroundMusic }))}>
					<img
						src="/images/bgMusicIcon.png"
						alt="musicIcon"
						className={`w-48 h-48 object-contain ${!playOptions.backgroundMusic ? 'opacity-50' : ''}`}
					/>
				</IconButton>
			</Tooltip>

			<Tooltip
				title={<Typography fontSize={14}>Subtítulos</Typography>}
				placement="top"
				arrow
				slotProps={slotProps}
				className="py-2"
			>
				<IconButton onClick={() => setPlayOptions((prev) => ({ ...prev, subtitles: !prev.subtitles }))}>
					<img
						src="/images/subtitlesIcon.png"
						alt="subtitlesIcon"
						className={`w-48 h-48 object-contain ${!playOptions.subtitles ? 'opacity-50' : ''}`}
					/>
				</IconButton>
			</Tooltip>

			<Tooltip
				title={<Typography fontSize={14}>Voz de audio</Typography>}
				placement="top"
				arrow
				slotProps={slotProps}
				className="py-2"
			>
				<IconButton onClick={() => setPlayOptions((prev) => ({ ...prev, audioVoice: !prev.audioVoice }))}>
					<img
						src="/images/audioVoiceIcon.png"
						alt="audioVoiceIcon"
						className={`w-48 h-48 object-contain ${!playOptions.audioVoice ? 'opacity-50' : ''}`}
					/>
				</IconButton>
			</Tooltip>

			<Tooltip
				title={<Typography fontSize={14}>Pantalla Completa</Typography>}
				placement="top"
				arrow
				className="py-2 pb-0"
				slotProps={slotProps}
			>
				<IconButton onClick={() => setPlayOptions((prev) => ({ ...prev, fullScreen: !prev.fullScreen }))}>
					<img
						src="/images/fullScreenIcon.png"
						alt="audioVoiceIcon"
						style={{
							objectPosition: '0 0',
							objectFit: 'cover',
						}}
						className={`w-48 h-[77px] object-cover overflow-hidden ${!playOptions.fullScreen ? 'opacity-50' : ''}`}
					/>
				</IconButton>
			</Tooltip>

			<Tooltip
				title={<Typography fontSize={14}>Librería</Typography>}
				placement="top"
				arrow
				slotProps={slotProps}
				className="pt-2"
			>
				<IconButton onClick={() => navigate('/library')}>
					<img
						src="/images/libraryIcon.png"
						alt="audioVoiceIcon"
						className={`w-48 h-52 object-cover overflow-hidden`}
					/>
				</IconButton>
			</Tooltip>
		</Box>
	);
};

export default PlayOptions;
