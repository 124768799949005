export const shuffleArray = async (array: any[]) => {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}
	return array;
};
export const shuffleQuestions = async (questions: any[]) => {
	return Promise.all(
		questions.map(async (question) => {
			const shuffledAnswers = await shuffleArray(question.answers);
			return {
				...question,
				answers: shuffledAnswers,
			};
		}),
	).then((shuffledQuestions) => {
		return shuffleArray(shuffledQuestions);
	});
};

export const months = [
	'Enero',
	'Febrero',
	'Marzo',
	'Abril',
	'Mayo',
	'Junio',
	'Julio',
	'Agosto',
	'Septiembre',
	'Octubre',
	'Noviembre',
	'Diciembre',
];
