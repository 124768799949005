import { AppBar, Box, Toolbar } from '@mui/material';
import { Link } from 'react-router-dom';

import { UserMenu } from '../menu';

const Header = () => {
	const colors = ['#e94f35', '#f2d243', '#aecc52', '#c472ac', '#4f8ecb', '#f39a38', '#804996', '#eb5d8d'];

	return (
		<AppBar position="fixed" className="bg-white flex shadow-sm border-b-1 fixed z-50">
			<Box className="flex-row hidden md:flex">
				{colors.map((color) => (
					<div
						key={color + 'ac'}
						className={`w-full h-4`}
						style={{
							backgroundColor: color,
						}}
					/>
				))}
			</Box>

			<Toolbar className="min-h-48 p-0 md:min-h-64 py-10">
				<Box className="flex flex-col gap-5 px-32">
					<Box className="flex flex-row items-center gap-[2em]">
						<Link to="/">
							<img src="/images/logo.svg" alt="logo" className="w-96" />
						</Link>

						<Box className="flex flex-col gap-2 text-black">
							<span>Somos parte de</span>
							<img src="/images/startup_chile.png" alt="logo" className="w-68" />
						</Box>
					</Box>
					<p className="font-medium text-[#4f8ecb]">¡Porque contar historias es un acto de amor!</p>
				</Box>
				<div className="flex h-full items-center overflow-x-auto gap-10 flex-1 justify-end">
					<UserMenu />
				</div>
			</Toolbar>
		</AppBar>
	);
};

export { Header };
