import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { jwtDecode } from 'jwt-decode';
import _ from 'lodash';
import { Suspense, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';

import { CustomAlert } from '../../components/custom-alert';
import { LoadingPage } from '../../components/loading-page';
import { ProfileSelectorPage } from '../../components/profile-selector-page';
import { useAlert } from '../../hooks/useAlert';
import { useAuth } from '../../hooks/useAuth';
import { useUser } from '../../hooks/useUser';
import { Background } from './components/background';
import { Header } from './components/header';

const Root = styled('div')(() => ({
	clipPath: 'inset(0)',
	maxWidth: '100%',
	margin: '0 auto',
	boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
	'& .container': {
		maxWidth: '100%',
		width: '100%',
		margin: '0 auto',
	},
}));

const DashboardLayout = () => {
	const { userInfo, studentSelected, courseSelected, selectStudent } = useUser();
	const { handleLogout } = useAuth();
	const { handleShowAlert } = useAlert();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const [showNav, setShowNav] = useState(true);

	const dontShowBackground = ['/profile'];

	useEffect(() => {
		if (_.isEmpty(userInfo)) {
			navigate('/auth/sign-in');
		}
		if (userInfo?.role === 'school-manager' && pathname !== '/') return navigate('/');
		else if (userInfo?.role === 'teacher' && pathname !== '/library') return navigate('/library');
		if (userInfo?.students && userInfo?.students.length === 1) {
			selectStudent(userInfo.students[0]._uid);
		}
	}, [userInfo]);

	useEffect(() => {
		const token = localStorage.getItem('token');

		if (token) {
			const decode = jwtDecode(token);
			if (decode.exp && decode?.exp < Date.now() / 1000) {
				setTimeout(() => {
					handleLogout();
				}, 5000);

				handleShowAlert({
					message: `Tu sesión ha expirado, serás redirigido al inicio de sesión en unos segundos.`,
					type: 'error',
				});
			}
		}
	}, [pathname]);

	const handleClassChange = (mutationsList: any) => {
		for (const mutation of mutationsList) {
			if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
				const valueLocalStorage = document.body.classList.contains('full-screen');
				setShowNav(!valueLocalStorage);
			}
		}
	};

	useEffect(() => {
		const observer = new MutationObserver(handleClassChange);
		const config = {
			attributes: true,
			attributeFilter: ['class'],
		};
		observer.observe(document.body, config);
		return () => {
			observer.disconnect();
		};
	}, []);

	return (
		<Box className="relative">
			{userInfo?.role !== 'school-manager' &&
				!_.isEmpty(userInfo) &&
				_.isEmpty(courseSelected) &&
				_.isEmpty(studentSelected) && <ProfileSelectorPage />}
			<Background showBackground={!dontShowBackground.includes(pathname)} />

			<Root
				className={`flex w-full relative overflow-x-hidden z-99 ${
					_.isEmpty(studentSelected) && _.isEmpty(courseSelected) && userInfo?.role !== 'school-manager' ? 'hidden' : ''
				}`}
			>
				<Box className="flex min-w-0 flex-auto ">
					<main className="relative z-10 flex min-h-full min-w-0 flex-auto flex-col ">
						<Box className="relative z-10 flex min-h-0 flex-auto flex-col mt-72 ">
							{pathname.includes('book') ? showNav && <Header /> : <Header />}

							<CustomAlert />
							<Suspense fallback={<LoadingPage />}>
								<Outlet />
							</Suspense>
						</Box>
					</main>
				</Box>
			</Root>
		</Box>
	);
};

export { DashboardLayout };
