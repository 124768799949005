import axios, { AxiosError, AxiosResponse } from 'axios';

import { CustomError } from '../interfaces/common';

const client = axios.create({
	baseURL: `${import.meta.env.VITE_API_BASE_URL}/api/webapp`,
	headers: {
		'Content-Type': 'application/json',
	},
});

client.defaults.headers.common['Content-Type'] = 'application/json';
client.defaults.headers.common.Accept = 'application/json';

client.interceptors.request.use(
	(request) => {
		const token = localStorage.getItem('token');
		if (token) request.headers.Authorization = `Bearer ${token}`;

		return request;
	},
	(error: AxiosError) => Promise.reject(error),
);

client.interceptors.response.use(
	(response: AxiosResponse) => response,
	async (error: AxiosError<CustomError>) => {
		if (error.response && error.response.status === 401) {
			window.location.href = '/auth/sign-in';
			window.localStorage.clear();
		}
		return Promise.reject(error);
	},
);

const { get, post, put, delete: destroy } = client;
export { destroy, get, post, put };
